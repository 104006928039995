<template>
  <div>
    <el-carousel height="110rem">
      <el-carousel-item>
        <img
          class="Image"
          src="../assets/img/niurou.jpg"
          mode="widthFix"
        />
      </el-carousel-item>
      <el-carousel-item>
        <img
          class="Image"
          src="../assets/img/yanji.jpg"
          mode="widthFix"
        />
      </el-carousel-item>
      <el-carousel-item>
        <img
          class="Image"
          src="../assets/img/youyu.jpg"
          mode="widthFix"
        />
      </el-carousel-item>
    </el-carousel>

    <div class="h_top">
      <div class="ht_left">
        <div class="htl_title">关于米悦</div>
        <div class="htl_font">主营朝鲜族各种拌饭类的研究与创新</div>
        <div class="htl_content">米悦朝鲜族拌饭，“只用当季延边大米，传承朝鲜族老手艺”，将延边的美食文化传播到全国各地，让更多人能享受美味的朝鲜族拌饭。</div>
        <div
          class="htl_btn"
          @click="getUrl('./about')"
        >查看详情 <span>→</span></div>
        <img src="../assets/img/miyueEngilsh.png" />
      </div>
      <div class="ht_right">
        <img
          src="../assets/img/img1.jpg"
          mode="center"
        />
      </div>
    </div>

    <div class="h_Image">
      <div class=hi_left>
        <img src="../assets/img/img2.jpg" />
      </div>
      <div class="hi_right">
        <img
          class="hir_Img"
          src="../assets/img/img3.jpg"
        />
        <div class="hir_Box">
          <img
            class="jiantouImage"
            src="../assets/img/hua.png"
          />
          <div class="hitb_title">石板豆腐</div>
          <div class="hitb_content"></div>
          <!-- <img
            class="jiantouImage"
            src="../assets/img/jiantou.png"
          /> -->
          <div class="hitb_cbox" style="font-size:2rem;margin-top:5rem;line-height:3.5rem">从嘴滑向胃<br>每一口都是“现磨”豆腐<br>米饭贴心伴侣</div>
          <!-- <div
            class="htl_btn"
            @click="getUrl('./Bestselling')"
          >探索下一个 <span>→</span></div> -->
        </div>
        <div class="hir_Box">
          <img
            class="jiantouImage"
            src="../assets/img/hua.png"
          />
          <div class="hitb_title">五花肉泡菜锅</div>
          <div class="hitb_content"></div>
          <!-- <img
            class="jiantouImage"
            style="transform: rotate(180deg);"
            src="../assets/img/jiantou.png"
          /> -->
          <div class="hitb_cbox" style="font-size:2rem;margin-top:5rem;line-height:3.5rem">酸辣解腻的泡菜<br>入口即化的五花肉<br>真是天造地设的一对儿</div>
          <!-- <div
            class="htl_btn"
            @click="getUrl('./Bestselling')"
          >探索下一个 <span>→</span></div> -->
        </div>
        <img
          class="hir_Img"
          src="../assets/img/img4.jpg"
        />
      </div>
    </div>

    <div class="h_zixun">
      <div class="hz_left">
        <div class="hzl_title">
          下面这个电话别小看它<br>
          或许就是你的转运密码！
        </div>
        <div class="hzl_content">400-886-6367</div>
      </div>
      <div class="hz_right">
        <div class="hzr_title">成就每一个餐饮创业梦想</div>
        <!-- <div class="hzr_titleTwo">副标题</div> -->
        <div class="hzr_content">截止2025年1月，米悦全国在营门店达200+家，入驻城市达47+,遍及全国17个省市自治区。</div>
        <div
          style="margin-top:8rem"
          class="htl_btn"
          @click="getUrl('./join')"
        >了解一下 <span>→</span></div>
        <div class="htl_bottom">
          <div class="htlb_box">
            <div class="htlbb_num">
              200
              <img style="right: -6rem;" src="@/assets/img/jia.png" />
            </div>
            <div class="htlbb_title">已拓展门店</div>
          </div>
          <div
            class="htlb_box"
            style="margin:0 14.6rem"
          >
            <div class="htlbb_num">
              47
              <img src="@/assets/img/jia.png" />
            </div>
            <div class="htlbb_title">入驻城市</div>
          </div>
          <div class="htlb_box">
            <div class="htlbb_num">
              17
              <img src="@/assets/img/jia.png" />
            </div>
            <div class="htlbb_title">遍及全国省市自治区</div>
          </div>
        </div>
      </div>
    </div>

    <div class="h_miyue">
      <img src="@/assets/img/miyue_bottom.png" />
      <div class="hm_title">米悦朝鲜族拌饭由具有全国千店成功运维经验的四有青年餐创集团全程孵化运营，致力于创新传承“朝鲜族老手艺”，让更多人品尝到朝鲜族拌饭这一美食。</br>
        依托集团优势，蜀海、华鼎将为米悦提供从城市到乡镇的全域仓配体系，7大个仓配中心，超200人运营服务团队为其保驾护航。</br>
        基于发展规划，米悦在2025年12月底将突破500家门店，与加盟商共创共赢。
    </div>
      <div class="hm_box">
        <div class="hm_small">
          <img src="@/assets/img/img5.jpg" />
          <!-- <div class="hms_title">二维码</div> -->
        </div>
      </div>
      <div class="hm_content">扫码关注「米悦朝鲜族拌饭」，第一时间获取相关资讯。<br>
        有任何意见或建议可通过公众号联系我们。</div>
      <div class="hm_bottom">
        ——这里是米悦的唯一官网——
      </div>
    </div>

    <!-- 测试 -->

    <Bottom @getTop="getTop"></Bottom>
  </div>
</template>

<script>
import Bottom from "@/components/bottom";

export default {
  data() {
    return {};
  },
  components: {
    Bottom
  },
  methods: {
    getUrl(url) {
      this.$router.push(url);
    },
    getTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth" // 平滑滚动
      });
    }
  }
};
</script>

<style lang="scss" scoped>
//媒体查询
@media screen and (max-width: 1200px) {
  .htl_btn {
    width: 30rem !important;
    height: 10rem !important;
    // font-size: 10px !important;
  }
  .hm_title{
    width: 100vw !important;
  }
  .h_top {
    width: 100vw !important;
    padding: 25rem 5rem 25.4rem 5rem !important;
  }
  .ht_right {
    margin-left: 10rem !important;
  }

  .hitb_cbox {
    line-height: 6.5rem !important;
    font-size: 0.8rem !important;
  }

  .h_Image {
    display: block !important;
    .hi_left {
      width: 100% !important;
      img {
        width: 100% !important;
      }
    }
  }

  .h_zixun {
    position: relative;
    padding: 24rem 0 20rem 0 !important;
    .hz_left {
      .hzl_title {
        width: 60rem !important;
      }
    }
    .hz_right {
      margin-left: 4rem !important;
      .hzr_content {
        line-height: 7.5rem !important;
      }
    }
    .htl_bottom {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100vw !important;
      display: flex;
      justify-content: space-around;
      align-items: center;
      .htlb_box {
        margin: 0 !important;
      }
    }
  }

  .hm_title{
    line-height: 7.6rem !important; 
  }

  .hm_content{
    line-height: 7.6rem !important; 
  }

  .htl_content{
    width: 64rem !important;
  }
}

.htl_btn {
  margin-top: 10rem;
  width: 25rem;
  height: 6rem;
  background: #000000;
  color: #fff;
  padding: 0 2.9rem;
  font-size: 2rem;
  font-weight: 400;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Image {
  width: 100%;
  height: 110rem;
}

.h_top {
  // width: 100vw;
  padding: 25rem 0 25.4rem 0;
  width: 152.1rem;
  margin: 0 auto;
  display: flex;
  // justify-content: space-between;
  align-items: center;
  .ht_left {
    .htl_title {
      font-size: 6rem;
      font-weight: bold;
      color: #e30920;
    }
    .htl_font {
      margin-top: 2rem;
      font-size: 3.6rem;
      color: #e30920;
    }
    .htl_content {
      margin-top: 5.5rem;
      width: 80rem;
      // line-height: 2.5rem;
      font-size: 1.6rem;
      font-weight: 300;
      color: #333333;
    }

    img {
      margin-top: 10rem;
      width: 9.3rem;
      height: 7.2rem;
    }
  }
  .ht_right {
    margin-left: 22rem;
    width: 50rem;
    height: 50rem;
    background: #eeeeee;
    img {
      width: 50rem;
      height: 50rem;
    }
  }
}

.h_Image {
  display: flex;
  .hi_left {
    width: 64rem;
    background: #eeeeee;
    img {
      width: 64rem;
      height: 100%;
    }
  }
  .hi_right {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    img {
      // width: 64rem;
      // height: 54rem;
      width: 50%;
      height: 50%;
    }
    .hir_Box {
      position: relative;
      padding-left: 5rem;
      // width: 64rem;
      // height: 54rem;
      width: 50%;
      height: 50%;
      .jiantouImage {
        width: 20rem;
        height: 20rem;
        position: absolute;
        right: 0;
        bottom: 0;
        // width: 2.1rem;
        // height: 1.6rem;
        // margin-top: 4.9rem;
      }
      .hitb_title {
        padding-top: 8.3rem;
        font-size: 7.2rem;
        font-weight: bold;
        color: red;
      }
      .hitb_content {
        margin-top: 4.9rem;
        width: 30rem;
        height: 0.5rem;
        background: red;
        margin-top: 4.9rem;
      }
    }
  }
}

.h_zixun {
  width: 100vw;
  height: 108rem;
  background-image: url("../assets/img/zixun.png");
  background-size: 100%;
  display: flex;
  padding: 24rem 0 20rem 15rem;
  .hz_left {
    width: 65rem;
    height: 60rem;
    background: #e30920;
    color: #fff;
    padding-left: 4.9rem;
    .hzl_title {
      width: 38rem;
      height: 8.2rem;
      font-size: 3rem;
      line-height: 6.5rem;
      letter-spacing: 0.2rem;
      font-weight: bold;
      margin-top: 14.9rem;
    }
    .hzl_content {
      margin-top: 22.2rem;
      font-size: 6rem;
      font-weight: bold;
    }
  }
  .hz_right {
    margin-left: 10rem;
    .hzr_title {
      margin-top: 3rem;
      font-size: 7.2rem;
      letter-spacing: 0.2rem;
      font-weight: bold;
      color: #e30920;
    }
    .hzr_titleTwo {
      // margin-top: .9rem;
      font-size: 6rem;
      font-weight: bold;
      color: #e30920;
    }
    .hzr_content {
      margin-top: 1.1rem;
      font-size: 1.6rem;
      font-weight: 300;
      color: #333333;
      line-height: 2.5rem;
    }
    .htl_bottom {
      display: flex;
      margin-top: 22.6rem;
      .htlb_box {
        .htlbb_num {
          width: 10rem;
          font-size: 7.2rem;
          font-weight: bold;
          color: #e30920;
          position: relative;
          img {
            width: 3rem;
            height: 3rem;
            position: absolute;
            top: 0rem;
            right: -3rem;
          }
        }
        .htlbb_title {
          // margin-top: 2rem;
          font-size: 2rem;
          font-weight: 300;
          color: #000000;
        }
      }
    }
  }
}

.h_miyue {
  // text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  img {
    width: 33.2rem;
    height: 8rem;
    margin-top: 10rem;
  }
  .hm_title {
    width: 140rem;
    line-height: 3.6rem;
    margin-top: 10rem;
    font-size: 2rem;
    font-weight: 400;
    color: #333333;
    text-align: center;
  }
  .hm_box {
    width: 88rem;
    display: flex;
    // justify-content: space-between;
    justify-content: center;
    margin-top: 8.5rem;
    .hm_small {
      text-align: center;
      img {
        width: 16rem;
        height: 16rem;
        background: #999;
      }
      .hms_title {
        margin-top: 3rem;
        font-size: 2rem;
        font-weight: 400;
        color: #333333;
      }
    }
  }
  .hm_content {
    margin-top: 4.9rem;
    width: 78rem;
    // height: 4.5rem;
    font-size: 2rem;
    font-weight: 400;
    color: #333333;
    line-height: 3.6rem;
    text-align: center;
  }
  .hm_bottom {
    font-size: 2.4rem;
    font-weight: 400;
    color: #333333;
    margin-top: 9.9rem;
    margin-bottom: 15.3rem;
  }
}
</style>
