<template>
  <div class="Bestselling">
    <ContentTop></ContentTop>
    <div class="a_content">
      <div class="ac_title">畅销产品</div>
      <div class="ac_box">
        <div class="ac_left">
          <img src="@/assets/img/img7.jpg" />
        </div>
        <div class="ac_right">
          <div class="acr_title">经典石锅拌饭</div>
           <div class="acr_content1">月销<span>27000碗</span></div>
          <!-- <div class="acr_content1">月销</div> -->
          <div class="acr_content3">桌桌都会点</div>
          <div class="acr_content3">销冠的底蕴是朝鲜族非遗文化的传承</div>
          <div class="acr_list"></div>
        </div>
      </div>
      <div class="ac_box">
        <div
          class="ac_right"
          style="text-align:left"
        >
          <div class="acr_title">香辣鱿鱼</div>
          <!-- <div class="acr_content">米悦经典销冠</div> -->
          <div class="acr_content1"><span>香辣Q爽</span></div>
          <div class="acr_content3" style="margin-top:2rem">这块咱可从来没输过谁！</div>
          <div class="acr_content3">好食材 好口感 好体验</div>
          <!-- <div class="acr_content1">全网好评率达</div>
                    <div class="acr_content2">98%</div>
                    <div class="acr_content3">累计卖出100，000碗</div> -->
        </div>
        <div class="ac_left">
          <img src="@/assets/img/img6.jpg" />
        </div>
      </div>
    </div>
    <div style="font-size: 2rem;margin: 2rem;text-align: right;">*页面中的27000碗数据来源于公司内部点餐平台和外卖平台的订单数据</div>

    <Bottom @getTop="getTop"></Bottom>

  </div>
</template>

<script>
import Bottom from "@/components/bottom";
import ContentTop from "../../components/content_top.vue";
export default {
  name: "aboutIndex",
  components: {
    ContentTop,
    Bottom
  },
  data() {
    return {};
  },

  mounted() {},

  methods: {
    getTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth" // 平滑滚动
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.Bestselling {
  .a_content {
    margin: 0 auto 2.5rem;
    width: 120rem;
    text-align: center;
    .ac_title {
      font-size: 3.6rem;
      font-weight: 400;
      color: #000000;
      margin: 5rem 0;
    }
    .ac_box {
      display: flex;
      justify-content: space-between;
      margin-bottom: 5rem;

      .ac_left {
        width: 80rem;
        height: 60rem;
        background: #eeeeee;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .ac_right {
        padding-top: 15rem;
        text-align: right;
        .acr_list {
          font-size: 2rem;
          color: #e30920;
          margin-top: 1.7rem;
        }
        .acr_title {
          font-size: 6rem;
          font-weight: bold;
          color: #e30920;
        }
        .acr_content {
          font-size: 2.5rem;
          font-weight: 500;
          color: #e30920;
          margin-top: 1rem;
        }
        .acr_content1 {
          margin-top: 10rem;
          font-size: 2rem;
          font-weight: 400;
          color: #333333;
          span {
            font-size: 3.6rem;
            color: #e30920;
            font-weight: bold;
          }
        }
        .acr_content2 {
          margin-top: 1.8rem;
          font-size: 3.6rem;
          font-weight: 500;
          color: #e30920;
        }
        .acr_content3 {
          margin-top: 1rem;
          font-size: 1.8rem;
          font-weight: 400;
          color: #333333;
        }
      }
    }
  }
}
</style>